import { TierLabel } from '@membership/models/Subscription';
import { Locale, toLocaleFromHostname } from '@peloton/internationalize';
import {
  formatMoney,
  toMoney,
  toCurrencyFromLocale,
} from '@peloton/internationalize/models/currency';

export const getLocalizedNYAppPromoPrice = (tierType: TierLabel) => {
  const locale = toLocaleFromHostname();
  const costInCents =
    tierType === TierLabel.DIGITAL_BASIC
      ? getAppOnePromoPriceInCents(locale)
      : getAppPlusPromoPriceInCents(locale);
  const monthlyPrice = formatMoney(toMoney(costInCents, toCurrencyFromLocale(locale)));
  return monthlyPrice;
};

export const getAppOnePromoPriceInCents = (locale: Locale) => {
  switch (locale) {
    case Locale.EnglishUnitedStates:
      return 249;
    case Locale.EnglishCanada:
      return 349;
    case Locale.EnglishUnitedKingdom:
      return 249;
    case Locale.GermanGermany:
      return 249;
    case Locale.EnglishAustralia:
      return 349;
    default:
      return 249;
  }
};
export const getAppPlusPromoPriceInCents = (locale: Locale) => {
  switch (locale) {
    case Locale.EnglishUnitedStates:
      return 499;
    case Locale.EnglishCanada:
      return 699;
    case Locale.EnglishUnitedKingdom:
      return 499;
    case Locale.GermanGermany:
      return 499;
    case Locale.EnglishAustralia:
      return 699;
    default:
      return 499;
  }
};
