import { BillingFrequency } from '@membership/models/Subscription';
import {
  getAppOnePromoPriceInCents,
  getAppPlusPromoPriceInCents,
} from '@membership/pricing/utils/getLocalizedNYAppPromoPrice';
import useTierDisplayPrice from '@membership/subscription-mobility/hooks/useTierDisplayPrice';
import { useMemo } from 'react';
import { useErrorReporter } from '@peloton/error-reporting/useErrorReporter';
import { useLocale } from '@peloton/next/hooks/useLocale';
import { getCatalog } from '@peloton/stores';
import { toDollars } from '@ecomm/models/Money';
import { useTierPricingQuery } from '@ecomm/pg-membership/graphql/TierPricingQuery.generated';

const useTierMobilityPrices = () => {
  const {
    errorReporter: { reportError },
  } = useErrorReporter();

  const locale = useLocale();

  const { data, error } = useTierPricingQuery({
    variables: {
      storeSlug: getCatalog(locale),
      platform: 'peloton',
    },
    suspend: false,
    reportSwallowedError: reportError,
    throwError: false,
  });

  const { toPriceInCents } = useTierDisplayPrice(data?.subscriptionTiers?.nodes ?? []);

  return useMemo(() => {
    if (error) {
      return {};
    }

    const appPriceMonthlyL1 = toPriceInCents(BillingFrequency.Monthly, 1);
    const appPriceMonthlyL2 = toPriceInCents(BillingFrequency.Monthly, 2);
    const appPriceAnnualL1 = toPriceInCents(BillingFrequency.Annual, 1);
    const appPriceAnnualL2 = toPriceInCents(BillingFrequency.Annual, 2);
    const appPriceAnnualBreakdownL1 = appPriceAnnualL1 / 12;
    const appPriceAnnualBreakdownL2 = appPriceAnnualL2 / 12;

    const appPriceMontlyL1NyPromo = getAppOnePromoPriceInCents(locale);
    const appPriceMontlyL2NyPromo = getAppPlusPromoPriceInCents(locale);

    const appSavingsL1 = appPriceMonthlyL1 * 12 - appPriceAnnualL1;
    const appSavingsL2 = appPriceMonthlyL2 * 12 - appPriceAnnualL2;

    const prices = {
      appPriceMonthlyL1: toDollars(appPriceMonthlyL1),
      appPriceMonthlyL2: toDollars(appPriceMonthlyL2),
      appPriceAnnualL1: toDollars(appPriceAnnualL1),
      appPriceAnnualL2: toDollars(appPriceAnnualL2),
      appPriceAnnualBreakdownL1: toDollars(appPriceAnnualBreakdownL1),
      appPriceAnnualBreakdownL2: toDollars(appPriceAnnualBreakdownL2),
      // we have to round down so we're not "lying" about savings
      appSavingsL1: Math.floor(toDollars(appSavingsL1)),
      appSavingsL2: Math.floor(toDollars(appSavingsL2)),
      appPriceMontlyL1NyPromo: toDollars(appPriceMontlyL1NyPromo),
      appPriceMontlyL2NyPromo: toDollars(appPriceMontlyL2NyPromo),
    };

    return prices;
  }, [toPriceInCents, error]);
};

export default useTierMobilityPrices;
