import {
  Button,
  Icon,
  grey,
  spacing,
  Flex,
  Badge,
  brand,
} from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { theUserIsInAustria } from '@peloton/internationalize';
import { useLocale } from '@peloton/next/hooks/useLocale';
import useNav from '@content/client/www/nav/useNav';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import type { ProductNavMeta } from '@ecomm/header/models';
import { LinkButton } from '@ecomm/internationalize-ui';
import ApparelSecondaryMenuFlyout from './ApparelSecondaryMenuFlyout';
import SecondaryMenuFlyout from './SecondaryMenuFlyout';

type Props = {
  data: ProductNavMeta;
  handleRequestClose: () => void;
  className?: string;
};

const MenuItemWithFlyout: React.FC<React.PropsWithChildren<Props>> = ({
  data: { cmsId },
  handleRequestClose,
  className = '',
}) => {
  const [secondaryPanelOpen, setSecondaryPanelOpen] = useState<boolean>(false);
  const closeSecondaryPanel = () => {
    trackCloseSecondaryPanel();
    setSecondaryPanelOpen(false);
  };
  const locale = useLocale();
  const { content, isLoading } = useNav(locale);
  const { trackEvent } = useTracking();
  const isNavBadgingUpdatesEnabled = useIsToggleActive()('nav_promo_change');

  const trackCloseSecondaryPanel = () => {
    trackEvent({
      event: 'Clicked Navigation',
      properties: {
        parent: 'MenuItemWithFlyout',
        linkName: 'Back',
        unitName: 'MenuItemWithFlyout',
        category: key ?? '',
      },
    });
  };

  if (isLoading) {
    return null;
  }

  const entry = content?.subnavs.find((subnav: any) => subnav.key === cmsId);

  if (!entry) {
    return null;
  }

  const { key, title, promoBadgeText, promoChangeText, ...nav } = entry;
  const setPromoBadgeText = isNavBadgingUpdatesEnabled ? promoChangeText : promoBadgeText;

  const hidePromoText =
    isNavBadgingUpdatesEnabled && promoChangeText && promoChangeText === 'hide';

  const displayPromoBadgeText = hidePromoText ? '' : setPromoBadgeText;

  const url = nav.products?.[0]?.primaryCta.url;
  //If LinkComponent is defined, we are in CRA and we need to use the `to` prop.

  const onClickStrengthApparel = () => {
    trackEvent({
      properties: {
        category: key,
        linkName: title,
        linkTo: url,
      },
    });
  };

  const onClickDeals = () => {
    trackEvent({
      properties: {
        category: key,
        linkName: title,
        linkTo: url,
      },
    });
    window.location.href = url;
  };

  const onClickApparelNew = () => {
    trackEvent({
      properties: {
        category: key,
        linkName: title,
      },
    });
    setSecondaryPanelOpen(true);
  };

  const onClickMenuItems = () => {
    trackEvent({
      properties: {
        category: key,
        linkName: title,
        linkTo: url,
      },
    });
    setSecondaryPanelOpen(true);
  };

  if (
    (cmsId as string) === 'nav.apparel' ||
    (cmsId as string) === 'nav.strength' ||
    theUserIsInAustria(locale)
  ) {
    return (
      <li className={className}>
        <ButtonChevronContainer>
          <StyledLinkButton
            data-test-id={key}
            href={url}
            text={title}
            color="dark"
            onClick={onClickStrengthApparel}
          />
          <ClickableContainer onClick={onClickStrengthApparel}>
            {displayPromoBadgeText && (
              <StyledBadge variant="horizontal" theme="accent">
                {displayPromoBadgeText}
              </StyledBadge>
            )}
            <StyledChevron
              name="chevron"
              primaryColor={grey[50]}
              aria-hidden={true}
              rotate={180}
              height={12}
            />
          </ClickableContainer>
        </ButtonChevronContainer>
      </li>
    );
  }

  if ((cmsId as string) === 'nav.apparelNew') {
    // TODO: New Apparel nav here
    return (
      <li className={className}>
        <ButtonChevronContainer>
          <Button
            aria-expanded={secondaryPanelOpen}
            data-test-id={key}
            children={title}
            color="dark"
            width="adjustable"
            onClick={onClickApparelNew}
          />
          <ClickableContainer onClick={onClickApparelNew}>
            {displayPromoBadgeText && (
              <StyledBadge variant="horizontal" theme="accent">
                {displayPromoBadgeText}
              </StyledBadge>
            )}
            <StyledChevron
              name="chevron"
              primaryColor={grey[50]}
              aria-hidden={true}
              rotate={180}
              height={12}
            />
          </ClickableContainer>
        </ButtonChevronContainer>
        <ApparelSecondaryMenuFlyout
          isOpen={secondaryPanelOpen}
          onCloseRequest={handleRequestClose}
          onBackRequest={closeSecondaryPanel}
          data={{ key, title, ...nav }}
        />
      </li>
    );
  }

  if ((cmsId as string) === 'nav.deals') {
    return (
      <li className={className}>
        <HighlightedButtonContainer alignItems="center">
          <StyledLinkButton
            data-test-id={key}
            href={url}
            text={title}
            color="dark"
            onClick={onClickDeals}
          />
          <ClickableContainer onClick={onClickDeals}>
            {displayPromoBadgeText && (
              <StyledBadge variant="horizontal" theme="accent">
                {displayPromoBadgeText}
              </StyledBadge>
            )}
          </ClickableContainer>
        </HighlightedButtonContainer>
      </li>
    );
  }

  return (
    <li className={className}>
      <ButtonChevronContainer>
        <Button
          aria-expanded={secondaryPanelOpen}
          data-test-id={key}
          children={title}
          color="dark"
          width="adjustable"
          onClick={onClickMenuItems}
        />
        <ClickableContainer onClick={onClickMenuItems}>
          {displayPromoBadgeText && (
            <StyledBadge variant="horizontal" theme="accent">
              {displayPromoBadgeText}
            </StyledBadge>
          )}
          <StyledChevron
            name="chevron"
            primaryColor={grey[50]}
            aria-hidden={true}
            rotate={180}
            height={12}
          />
        </ClickableContainer>
      </ButtonChevronContainer>
      <SecondaryMenuFlyout
        isOpen={secondaryPanelOpen}
        onCloseRequest={handleRequestClose}
        onBackRequest={closeSecondaryPanel}
        data={{ key, title, ...nav }}
      />
    </li>
  );
};

export default MenuItemWithFlyout;

const StyledChevron = styled(Icon)`
  margin-left: ${spacing[12]};
`;

const ButtonChevronContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ClickableContainer = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const StyledLinkButton = styled(LinkButton)`
  width: auto !important;
  padding: unset !important;
`;

const HighlightedButtonContainer = styled(Flex)`
  color: ${brand.accent} !important;

  a {
    color: currentColor !important;
  }
`;

const StyledBadge = styled(Badge)`
  font-size: 13px;
  border-radius: ${spacing[4]}px;
  padding: ${spacing[4]}px ${spacing[8]}px;
  margin-left: ${spacing[12]};
`;
