import React, { useContext } from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { useGetPartnerParamsFromStorage } from '@ecomm/cart-next/hooks/usePartnerPromo';
import { PromoContext } from '@ecomm/cms-promos';
import { useIsToggleActive } from '@ecomm/feature-toggle';
import useHasFinancingPartner from '@ecomm/financing/hooks/useHasFinancingPartner';
import { Product, useProductStates } from '@ecomm/product-states';
import PartnerLegalSection from './PartnerLegalSection';
import ProductLegalSection from './ProductLegalSections';
import PromoLegalSection from './PromoLegalSection';

const useProductStateLegalSelector = () => {
  const { isProductAvailableForMarketingPages } = useProductStates();

  return {
    bikeEnabled: isProductAvailableForMarketingPages(Product.Bike),
    bikePlusEnabled: isProductAvailableForMarketingPages(Product.BikePlus),
    isTreadAvailable: isProductAvailableForMarketingPages(Product.Tread),
    isTreadPlusAvailable: isProductAvailableForMarketingPages(Product.TreadPlus),
    isRowAvailable: isProductAvailableForMarketingPages(Product.Row),
  };
};

const LegalSection: React.FC<React.PropsWithChildren<unknown>> = () => {
  const promo = useContext(PromoContext);
  const { end, legalTextId, start } = promo;
  const hasFinancingPartner = useHasFinancingPartner();

  const isToggleActive = useIsToggleActive();
  const waysToTryEnabled = isToggleActive('waysToTry');
  const partnerParams = useGetPartnerParamsFromStorage();
  const showPartnerPromoSection = Boolean(
    partnerParams?.referralCode && partnerParams?.partnerParamsPresent,
  );
  const showPromoLegalSection = legalTextId && start && end;

  const productState = useProductStateLegalSelector();
  const {
    isTreadAvailable,
    bikeEnabled,
    bikePlusEnabled,
    isTreadPlusAvailable,
    isRowAvailable,
  } = {
    ...productState,
  };

  const legalSectionProps = {
    bikeEnabled,
    bikePlusEnabled,
    isTreadAvailable,
    isTreadPlusAvailable,
    isRowAvailable,
    hasFinancingPartner,
    waysToTryEnabled,
  };

  return (
    <Container data-test-id="legalSection">
      {showPromoLegalSection && (
        <PromoLegalSection
          legalTextId={legalTextId}
          start={new Date(start)}
          end={new Date(end)}
        />
      )}
      <ProductLegalSection {...legalSectionProps} />
      {showPartnerPromoSection && <PartnerLegalSection />}
    </Container>
  );
};

export default LegalSection;

const Container = styled.section`
  background-color: inherit;
  margin: auto;
  padding: 30px 20px 0px;

  ${media.tablet`
    padding: 25px 20px 0px
  `}

  ${media.desktop`
    padding: 40px 50px 0px;
  `}
`;
