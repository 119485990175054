import { Container, Flex, spacing } from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import { Locale, theUserIsInAustralia, useLocale } from '@peloton/internationalize';
import type { ProductDetailsCardData } from '@ecomm/copy/helpers/types';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import {
  APP_NAV_PRODUCT_CARD_KEY,
  ACCESSORIES_NAV_PRODUCT_CARD_KEY,
  STRENGTH_PLUS_NAV_PRODUCT_CARD_KEY,
  GUIDE_NAV_PRODUCT_CARD_KEY,
} from '../../constants';
import { ProductCardContainer } from './ProductCardContainer';
import { ProductCardText } from './ProductCardText';
import { ProductExploreButton } from './ProductExploreButton';
import { ProductImage } from './ProductImage';
import { ProductCardDirection } from './types';

export const setProductCardDirection = (
  showSingleProductCtaSection: boolean,
  key: string,
  locale?: Locale,
  showStrengthPlusInNav?: boolean,
) => {
  const isAustraliaLocale = theUserIsInAustralia(locale ?? Locale.EnglishUnitedStates);

  if (
    showSingleProductCtaSection ||
    (key === APP_NAV_PRODUCT_CARD_KEY && !showStrengthPlusInNav) ||
    key === ACCESSORIES_NAV_PRODUCT_CARD_KEY ||
    (key === GUIDE_NAV_PRODUCT_CARD_KEY && isAustraliaLocale)
  ) {
    return ProductCardDirection.Horizontal;
  } else {
    return ProductCardDirection.Vertical;
  }
};

export const shouldShowShopCTA = (key: string) => {
  return key !== ACCESSORIES_NAV_PRODUCT_CARD_KEY;
};

type ProductCardProps = {
  productCard: ProductDetailsCardData;
  showSingleProductCtaSection: boolean;
};

export const ProductCard: React.FC<React.PropsWithChildren<ProductCardProps>> = ({
  productCard,
  showSingleProductCtaSection,
}) => {
  const isStrengthPlusCard = productCard.key === STRENGTH_PLUS_NAV_PRODUCT_CARD_KEY;
  const shouldShowStrengthCard = useIsToggleActive()('showStrengthPlusInNav');
  const [isHovered, setIsHovered] = useState(false);
  const locale = useLocale();

  if (isStrengthPlusCard && !shouldShowStrengthCard) {
    return null;
  }

  const direction = setProductCardDirection(
    showSingleProductCtaSection,
    productCard.key,
    locale,
    shouldShowStrengthCard,
  );
  const showShopButton = shouldShowShopCTA(productCard.key);

  return (
    <ProductCardContainer
      direction={direction}
      productCard={productCard}
      showShopButton={showShopButton}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      isHovered={isHovered}
    >
      {direction === ProductCardDirection.Horizontal ? (
        <>
          <ProductImage image={productCard.image} />
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            gap={spacing[24]}
          >
            <ProductCardText direction={direction} productCard={productCard} />
            <ProductExploreButton
              direction={direction}
              productCard={productCard}
              isHovered={isHovered}
            />
          </Flex>
        </>
      ) : (
        <>
          <Container padding={{ desktop: `0 ${spacing[24]} 0 0` }}>
            <ProductCardText direction={direction} productCard={productCard} />
          </Container>

          <Flex flexDirection="column" gap={spacing[16]}>
            <Flex justifyContent="center">
              <ProductImage
                image={productCard.image}
                promoBadgeText={productCard.promoBadgeText}
                promoChangeText={productCard?.promoChangeText}
              />
            </Flex>
            <ProductExploreButton
              direction={direction}
              productCard={productCard}
              isHovered={isHovered}
            />
          </Flex>
        </>
      )}
    </ProductCardContainer>
  );
};
